import React from "react";
import "./css/index.css";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import About from "./components/pages/About";
import Home from "./components/pages/Home";
import Video from "./components/pages/Video";

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home}></Route>
        <Route path="/about" component={About}></Route>
        <Route path="/video" component={Video}></Route>
        <Route path="/home" component={Home}></Route>
      </Switch>
    </Router>
  );
}

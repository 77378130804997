import React from "react";
import { withRouter } from "react-router-dom";
import Menu from "../Menu";
import Share from "../Share";

import log from "../../services/log.service";

import "../../css/home.css";

class Home extends React.Component {
  constructor({ history }) {
    super();
    this.history = history;
    this.onClick = this.onClick.bind(this);
    this.log = log("component:home");
  }

  onClick() {
    this.history.push("/video");
  }

  componentDidMount() {
    const btn = document.getElementById("click-me-btn");
    btn.className = "click-me-button";

    setTimeout(() => {
      btn.className = "click-me-button-pressed";
      setTimeout(() => {
        btn.className = "click-me-button";
        setTimeout(() => {
          btn.className = "click-me-button-pressed";
          setTimeout(() => {
            btn.className = "click-me-button";
          }, 200);
        }, 300);
      }, 300);
    }, 300);

    btn.ontouchend = () => {
      this.log("set:click-me-button");
      btn.className = "click-me-button";
    };
    btn.ontouchstart = () => {
      this.log("set:click-me-button-pressed");
      btn.className = "click-me-button-pressed";
    };
    btn.onmouseover = () => {
      this.log("set:click-me-button-pressed");
      btn.className = "click-me-button-pressed";
    };
    btn.onmouseleave = () => {
      this.log("set:click-me-button");
      btn.className = "click-me-button";
    };
    this.log(this.componentDidMount.name);
  }

  render() {
    return (
      <section className="home-container">
        <Menu cssClass="menu-button-home" />
        <section className="content-container">
          <div className="logo" />
          <section className="logo-text">
            <p className="icon-title">project</p>
            <p className="icon-sub-title">by Marina Economidou & Pavel Durov</p>
          </section>
          <div
            id="click-me-btn"
            onClick={this.onClick}
            className="click-me-button"
          />
          <Share
            className="home-footer"
            instagramBtnClassName="instagram-icon"
            facebookBtnClassName="facebook-icon"
          />
        </section>
      </section>
    );
  }
}

export default withRouter(Home);

// modules/About.js
import React, { Component } from 'react';
import Menu from '../Menu';

import '../../css/about.css'

import log from "../../services/log.service";

class About extends Component {
  constructor(){
    super();
    this.log = log("component:about");
    this.instagramLink = 'https://www.instagram.com/i.love.you.project/';
  }
  componentDidMount(){
    this.log(this.componentDidMount.name);
  }
  render() {
    return (
    <section>
      <Menu cssClass='menu-button-about'/>
      <section className="about-container">
        <p className="title">About</p>
        <p>
          The idea behind this project came from thinking about this phrase - ‘I love you’; what it means to me, and specifically the way it is used in cinema.  
        </p>
        <p>
        It’s a universal phrase, and a very common one that can be found in nearly every film, spanning across every genre; but the way it is used varies dramatically from its actual meaning. In cinema, ‘I love you’ can be sarcastic, angry, funny, a goodbye, etc.
        </p>
        <p>
        Creating a compilation of ‘I love you’ scenes taken out of context, exaggerates that moment and exposes the somewhat ridiculous use of it.
        </p>
        <p> The ‘I love you’ scenes were collected with the help of many participants who contributed their favourite scenes on instagram! (<a href={this.instagramLink}>you can do it too!</a>)</p>
        
        
        <span className="signature">&#9829; Marina</span>
      </section>
    </section>)
  }
};

export default About;
export const FACE_BOOK_SHARE_LINK = `http://www.facebook.com/sharer.php?s=100
  &p[title]=${encodeURIComponent("I love you project")}
  &p[url]=${encodeURIComponent("https://iloveyouproject.herokuapp.com")}
  &p[images][0]=${encodeURIComponent(
    "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/imgs/iloveyou-preview.png"
  )}`;

export const INSTAGRAM_SHARE_LINK =
  "https://www.instagram.com/i.love.you.project/";

export const publicMediaFiles = [
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/0.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/1.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/2.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/3.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/4.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/5.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/6.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/7.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/8.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/9.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/10.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/11.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/12.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/13.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/14.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/15.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/16.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/17.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/18.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/19.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/20.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/21.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/22.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/23.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/24.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/25.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/26.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/27.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/28.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/29.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/30.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/31.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/32.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/33.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/34.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/35.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/36.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/37.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/38.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/39.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/40.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/41.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/42.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/43.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/44.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/45.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/46.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/47.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/48.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/49.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/50.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/51.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/52.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/53.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/54.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/55.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/56.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/57.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/58.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/59.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/60.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/61.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/62.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/63.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/64.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/65.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/66.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/67.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/68.mp4",
  "https://iloveyou-public-bucket.s3.eu-west-2.amazonaws.com/2020-06-20/69.mp4",
];

import React from "react";
import { FACE_BOOK_SHARE_LINK, INSTAGRAM_SHARE_LINK } from "../config/const";

function Share({className, instagramBtnClassName, facebookBtnClassName}) {
  return (
    <section className={className}>
      <p>spread the love:</p>
      <a
        className={instagramBtnClassName}
        href={INSTAGRAM_SHARE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      ></a>
      <a
        className={facebookBtnClassName}
        href={FACE_BOOK_SHARE_LINK}
        target="_blank"
        rel="noopener noreferrer"
      ></a>
    </section>
  );
}

export default Share;

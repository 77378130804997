import React from 'react';
import { withRouter } from 'react-router-dom'

class Menu extends React.Component {
  constructor({ history, cssClass}) {
    super();
    this.cssClass = cssClass;
    this.history = history;
    this.onClick = this.onClick.bind(this);
    this.clicked = false;
  }
  onClick() {
    if (this.cssClass === 'menu-button-home') {
      this.history.push('/about');
    } else {
      this.history.push('/home');
    }
    this.clicked = !this.clicked;
  }

  render() {
    return (
      <div type="button" onClick={this.onClick} className={this.cssClass}>
      </div>
    )
  }
}

export default withRouter(Menu);
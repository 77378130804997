import React, { Component } from "react";

import Menu from "../Menu";
import Share from "../Share";

import log from "../../services/log.service";

import { publicMediaFiles } from "../../config/const";

import "../../css/video.css";

class Video extends Component {
  constructor() {
    super();
    this.onPlayClick = this.onPlayClick.bind(this);
    this.onVideoEnded = this.onVideoEnded.bind(this);
    this.onError = this.onError.bind(this);
    this.onPlay = this.onPlay.bind(this);
    this.init = this.init.bind(this);
    this.onLoadedData = this.onLoadedData.bind(this);
    this.state = {
      isPlaying: false,
      isComplete: false,
    };
    this.log = log("component:video");
  }
  componentWillUnmount() {
    this.log(this.componentWillUnmount.name);
    document.getElementById("video-0").pause();
  }

  componentDidMount() {
    this.init();
    const btn = document.getElementById("generate-btn");
    btn.className = "generate-btn";
    btn.ontouchend = () => {
      btn.className = "generate-btn";
    };
    btn.ontouchstart = () => {
      btn.className = "generate-btn-pressed";
    };
    btn.onmouseover = () => {
      btn.className = "generate-btn-pressed";
    };
    btn.onmouseleave = () => {
      btn.className = "generate-btn";
    };
    this.log(this.componentDidMount.name);
  }

  getRandomMedia(files) {
    const otherFiles = files.filter(
      (media) => media !== this.state.videoSrc
    );
    const index = Math.floor(Math.random() * otherFiles.length);
    return otherFiles[index];
  }
  init() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.setState({ isComplete: false });
    this.timeout = setTimeout(() => {
      try {
        this.log(this.init.name);
        const videoSrc = this.getRandomMedia(publicMediaFiles);
        this.setState({
          videoSrc,
          isPlaying: true,
          isComplete: false,
        });
        this.log(this.init.name, { videoSrc });
      } catch (e) {
        this.log(this.init.name, { e });
      }
    }, 2000);
  }

  onPlay() {
    this.log(this.onPlay.name);
    // DEBUG: stop video for debugging end
    // this.setState({ isPlaying: false, isComplete: true });
    // setTimeout(() => this.onVideoEnded(), 2000);
  }

  onLoadedData() {
    this.log(this.onLoadedData.name);
    this.setState({
      isPlaying: true,
    });
  }

  onError(error) {
    this.log(this.onError.name, { error });
    if (error.name !== "NotAllowedError") {
      this.init();
    }
    this.setState({
      isPlaying: false,
    });
  }

  onVideoEnded() {
    this.log(this.onVideoEnded.name);
    this.setState({
      isPlaying: false,
      isComplete: true,
    });
  }
  onPlayClick(btn) {
    this.log(this.onPlayClick.name);
    if (this.state.isComplete) {
      this.init();
    }
  }

  render() {
    return (
      <section className="video-content">
        <Menu cssClass="menu-button-video" />
        <section className="text-container" hidden={this.state.isComplete}>
          <p>generating new sequence</p>
        </section>
        <section
          className="text-container"
          hidden={!this.state.isComplete || this.state.isPlaying}
        >
          <div
            id="generate-btn"
            onClick={(btn) => this.onPlayClick(btn)}
            className="click-me-button"
          />
          <Share
            className="share-container"
            instagramBtnClassName="instagram-white-icon"
            facebookBtnClassName="facebook-video-icon"
          />
        </section>

        <section className="video-player-container">
          <video
            id="video-0"
            type="video/mp4"
            hidden={!this.state.isPlaying}
            play={this.state.isPlaying ? "true" : "false"}
            src={this.state.videoSrc}
            onEnded={this.onVideoEnded}
            onError={this.onError}
            onPlay={this.onPlay}
            onLoadedData={this.onLoadedData}
            autoPlay={true}
            controls={false}
            onClick={this.play}
            playsInline
            controlsList="nodownload nofullscreen noremoteplayback"
          ></video>
        </section>
      </section>
    );
  }
}

export default Video;
